import {
  TYPE_ENV_TEST,
  TYPE_ENV_DEV,
  TYPE_ENV_MAIN,
  avb_tokenList,
  avb_explorerURL,
  avb_apiURL,
  avb_gatewayURL,
  TYPE_ENV_DEV2
} from 'avb_data';



export const dAppName = 'xSmartDeploy';
//export const environment = TYPE_ENV_TEST; // ==> TESTNET
//export const environment = TYPE_ENV_MAIN; // ==> MAINNET
export const environment = TYPE_ENV_DEV; // ==> DEVNET


export const apiUrl = avb_apiURL[environment]; //url Api
export const explorerUrl = avb_explorerURL[environment]; //url explorer
export const tokenList = avb_tokenList[environment]; // lista de tokens disponibles
export const gatewayUrl = avb_gatewayURL[environment]; //url Gateaway


export const dappMainnet = ''; //'https://xsmartdeploy.com';
export const dappTesnet = '';//'https://testnet.xsmartdeploy.com';
export const dappDevnetV1 = 'https://devnet.xsmartdeploy.com';
export const dappDevnetV2 = '';//'https://devnet2.xsmartdeploy.com';

export const egld = 'EGLD';
export const usdc = 'USDC-8d4068';
export const owner = 'erd1qyu5wthldzr8wx5c9ucg8kjagg0jfs53s8nr3zpz3hypefsdd8ssycr6th';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '16b870ee63e0088fc161e3f4a0bb08af';


