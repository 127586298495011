import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './common.scss';
import jsonIcon from '../../assets/img/json.png';

import Tooltip from '@mui/material/Tooltip';



export interface InputValue {
    name: string;
    type: string;
    value: string;
}

interface ABIUploadProps {
    onInputChange: (inputValues: InputValue[]) => void;
}

const ABIUpload: React.FC<ABIUploadProps> = ({ onInputChange }) => {
    // State for selected file information
    const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
    const allowedExtensions = ['json'];

    // State for showing form fields and code content
    const [showFields, setShowFields] = useState(false);
    const [InputValues, setInputValues] = useState<InputValue[]>([]);
    const [codeContent, setCodeContent] = useState<string>('');

    // Handle file drop
    const handleFileDrop = useCallback(async (acceptedFiles: File[]) => {
        const selectedFile = acceptedFiles[0];
        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase() || '';

        if (!allowedExtensions.includes(fileExtension)) {
            alert('Only JSON files are allowed.');
            return;
        }

        setSelectedFileName(selectedFile.name);
        setShowFields(true);

        try {
            const fileReader = new FileReader();

            fileReader.onload = (event) => {
                const content = event.target?.result as string;
                setCodeContent(content);

                try {
                    const parsedContent = JSON.parse(content);
                    const inputs = parsedContent.constructor.inputs;
                    setInputValues(inputs.map((input: any) => ({
                        name: input.name,
                        type: input.type,
                        value: '',
                    })));

                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };

            fileReader.onerror = (event) => {
                console.error('Error reading the file:', event.target?.error);
            };

            fileReader.readAsText(selectedFile);
        } catch (error) {
            console.error('Error reading the file:', error);
        }
    }, []);

    // Handle input change
    const handleInputChange = (name: string, value: string, typeData: string) => {
        const updatedInputValues = InputValues.map(input => {
            if (input.name === name) {
                return { ...input, value };
            }
            return input;
        });

        setInputValues(updatedInputValues);
        onInputChange(updatedInputValues);
    };

    // Handle form input change
    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const input = InputValues.find(input => input.name === name);

        if (input) {
            handleInputChange(input.name, value, input.type);
        }
    };

    // Set up dropzone configuration
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleFileDrop });

    return (
        <div className="upload-container" style={{ color: '#fff' }}>
            {selectedFileName ? (
                <div className="selected-file">
                    <img src={jsonIcon} alt="JSON Icon" className="icon" />
                    <p>{selectedFileName}</p>
                    {showFields && (
                        <div className="input-fields">
                            <form>
                                {InputValues.map((input, index) => (
                                    <div className="input-container" key={index}>
                                        <label htmlFor={input.name}>{input.name}</label>
                                        <Tooltip title={`Type: ${input.type}`} placement="top">
                                            <input
                                                type="text"
                                                id={input.name}
                                                name={input.name}
                                                value={input.value}
                                                onChange={handleFormChange}
                                                style={{ width: '100%', borderRadius: '10px', textAlign: 'right' }}
                                            />
                                        </Tooltip>
                                    </div>
                                ))}
                            </form>

                        </div>
                    )}
                </div>


            ) : (
                <div className={`file-upload-container dropzone ${isDragActive ? 'active' : ''}`} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className='text_grey'>Drag and drop JSON files here, or click to select files</p>
                </div>
            )}
        </div>
    );
};

export default ABIUpload;