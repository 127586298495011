import { Address, TokenPayment } from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { explorerUrl, tokenList, apiUrl } from 'config';


function formatTokenIdentifier(tokenIdentifier: string) {
    tokenIdentifier.split('-');
    return tokenIdentifier.split('-').length > 1 ? tokenIdentifier.split('-')[0] : tokenIdentifier;
}
function hexToDecimal(data: string) {
    return parseInt(new BigNumber(data, 16).toString(10));
}

function decimalToHex(data: string) {
    const bn = new BigNumber(data, 10);
    let bnStr = bn.toString(16);
    if (bnStr.length % 2 !== 0) {
        bnStr = '0' + bnStr;
    }
    return bnStr;
}


function hexToString(data: string) {
    return Buffer.from(
        data,
        'hex'
    ).toString('utf8');
}

function base64ToHex(data: string) {
    return Buffer.from(
        data,
        'base64'
    ).toString('hex').toString();
}

function base64ToString(data: string) {
    return Buffer.from(data, 'base64').toString('ascii');
}

function stringToBase64(data: string) {
    return Buffer.from(data, 'ascii').toString('base64');
}

function stringToHex(data: string) {
    return Buffer.from(data, 'ascii').toString('hex');
}

function addressToHex(data: string) {
    if (data.length !== 62) {
        return undefined;
    } else {
        return Address.fromBech32(data).hex();
    }
}


// FUNCION QUE DEVUELVE EL BALANCE DE CADA TOKEN.
// PARAMETROS VALOR Y TOKEN
function convertBalance(data: string, token = 'EGLD') {
    const filteredTokens = tokenList.filter(function (token_element) {
        return token_element.tokenIdentifier === token;
      });
    return TokenPayment.fungibleFromBigInteger(token, parseInt(data), filteredTokens[0].decimals).toPrettyString();
}


function decodeElementInt(data: string, start: number, finish: number, num: number) {
    start = finish;
    finish = start + num;
    return {
        element: hexToDecimal(data.substring(start, finish)).toString(),
        start: start,
        finish: finish
    };
}

function decodeElementStr(data: string, start: number, finish: number, num: number, balance: boolean, tokenIdentifier = 'EGLD') {
    start = finish;
    finish = start + num;
    const size = hexToDecimal(data.substring(start, finish)) * 2;
    start = finish;
    finish = start + size;
    let value = hexToString(data.substring(start, finish));

    if (balance) {
        if (tokenIdentifier !== 'null') {
            const s = convertBalance(data.substring(start, finish), tokenIdentifier);
        } else {
            value = hexToDecimal(data.substring(start, finish)).toString();
        }
    }
    return {
        element: value,
        start: start,
        finish: finish
    };
}
function decodeElementAddress(data: string, start: number, finish: number, num: number) {
    start = finish;
    finish = start + num;
    const address = data.substring(start, finish);
    // start = finish;
    // finish = start + num;
    let value = null;
    if (address) {
        value = Address.fromHex(address).bech32();
    }
    else {
        value = '';
    }
    return {
        element: value,
        start: start,
        finish: finish
    };
}

function verifyValueByType(type: string, value: any): boolean {
    switch (type) {
        case 'u64':
            // Check for 'u64' type here
            // For example, verify if `value` is a valid number
            value = parseInt(value, 10);
            return typeof value === 'number';

        case 'Address':
            try {
                const address = new Address(value);
                return true;
            } catch (error) {
                // La dirección es inválida
                return false;
            }

            case 'TokenIdentifier':
                return typeof value === 'string' && /^[a-zA-Z]+-[a-zA-Z]+$/.test(value);
    

        default:
            // Unknown type, return false
            return false;
    }
}


// Usage examples:
/*
console.log(verifyValueByType('u64', 42)); // true
console.log(verifyValueByType('Address', 'erd125e86nl69et3qaltwxmwhluwk786fq3j0ve6636uzqz44mkwwvqqe2u6um')); // true
console.log(verifyValueByType('Address', 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqnhllllsexcqt3')); // true
console.log(verifyValueByType('TokenIdentifier', 'ETH')); // true
console.log(verifyValueByType('u64', 'string')); // false
console.log(verifyValueByType('UnknownType', 'value')); // false
*/

export {
    hexToDecimal,
    hexToString,
    base64ToHex,
    base64ToString,
    stringToBase64,
    stringToHex,
    addressToHex,
    convertBalance,
    decimalToHex,
    formatTokenIdentifier,
    decodeElementInt,
    decodeElementStr,
    decodeElementAddress,
    verifyValueByType,
};
