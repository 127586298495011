import React, { useEffect } from 'react';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton
} from '@multiversx/sdk-dapp/UI';
import { walletConnectV2ProjectId } from 'config';
import { routeNames } from 'routes';
import './UnlockRoute.scss';

export const UnlockRoute: React.FC = () => {
  const isLoggedIn = useGetIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = routeNames.home;
    }
  }, [isLoggedIn]);

  const commonProps = {
    callbackRoute: routeNames.home,
    nativeAuth: true // optional
  };

  return (
    <div className='home d-flex flex-fill align-items-center'>
      <div className='m-auto' data-testid='unlockPage'>
        <div className='card my-4 text-center'>
          <div className='py-4 px-2 px-sm-2 mx-lg-4'>
            <h4 className='mb-4'>Login</h4>
            <p className='mb-4'>Pick a login method</p>

            <div className='button-container'>
              <ExtensionLoginButton loginButtonText='DeFi Extension' {...commonProps} />

              <WebWalletLoginButton loginButtonText='Web wallet' {...commonProps} />

              <LedgerLoginButton loginButtonText='Ledger' {...commonProps} />

              <WalletConnectLoginButton
                loginButtonText='xPortal'
                {...commonProps}
                {...(walletConnectV2ProjectId ? { isWalletConnectV2: true } : {})}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockRoute;
