import * as React from 'react';


import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { getChainID, refreshAccount } from '@multiversx/sdk-dapp/utils';

import { decimalToHex, stringToHex } from 'lib/functions';
import { Account, Address, AddressValue, CodeMetadata, SmartContract, TokenIdentifierValue, U64Value } from '@multiversx/sdk-core/out';
import { InputValue } from 'components/DragDrop/ABIUpload';
import { BooleanValues } from 'pages/Dashboard';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

export const deploySC = async function (booleanValues: BooleanValues, inputValues: InputValue[], codeContent: string, address: string) {
    let tx = {};


    let contract = new SmartContract();


    const initArguments = inputValues.map((inputValue) => {
        switch (inputValue.type) {
            case 'Address':
                // Si el tipo es 'Address', realiza la operación adecuada
                return new AddressValue(Address.fromBech32(inputValue.value));
            case 'TokenIdentifier':
                // Si el tipo es 'TokenIdentifier', realiza la operación adecuada
                return new TokenIdentifierValue(inputValue.value);
            case 'u64':
                // Si el tipo es 'u64', realiza la operación adecuada
                return new U64Value(inputValue.value);
            // Agrega más casos para otros tipos si es necesario
            default:
                // Maneja otros tipos aquí si es necesario
                throw new Error(`Tipo desconocido: ${inputValue.type}`);
        }
    });


    const deployTransaction = contract.deploy({
        deployer: new Address(address),
        code: codeContent,
        codeMetadata: new CodeMetadata(booleanValues.upg, booleanValues.read, booleanValues.pay, booleanValues.paySC),
        initArguments: initArguments,
        gasLimit: 20000000,
        chainID: getChainID(),
    });


    const deployer = new Account(new Address(address));

    deployTransaction.setNonce(deployer.getNonceThenIncrement());


    const { sessionId , error } = await sendTransactions({
        transactions: deployTransaction,
        transactionsDisplayInfo: {
            processingMessage: 'Deploying SC...',
            errorMessage: 'An error has occured during deploy SC',
            successMessage: 'Deploy SC successful'
        },
        redirectAfterSign: false
    });
    if (sessionId != null) {
        return sessionId;
    }

    let contractAddress = SmartContract.computeAddress(deployTransaction.getSender(), deployTransaction.getNonce());
    console.log("Contract address:", contractAddress.bech32());
    alert(contractAddress.bech32());

    

};