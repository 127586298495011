import React from 'react';
import './toggle.scss';
import Switch from 'react-switch';

interface ToggleFormProps {
    upgradeable: boolean;
    readable: boolean;
    payable: boolean;
    payableSC: boolean;
    onupgradeableChange: (newValue: boolean, name: string) => void;
    onreadableChange: (newValue: boolean, name: string) => void;
    onpayableChange: (newValue: boolean, name: string) => void;
    onpayableSCChange: (newValue: boolean, name: string) => void;
}


const ToggleForm: React.FC<ToggleFormProps> = ({
    upgradeable,
    readable,
    payable,
    payableSC,
    onupgradeableChange,
    onreadableChange,
    onpayableChange,
    onpayableSCChange,


}) => {

    const handleUpgradeableChange = () => {
        onupgradeableChange(!upgradeable, 'upgradeable'); // Aquí pasamos el nuevo valor y el nombre del booleano
    };

    const handleReadableChange = () => {
        onreadableChange(!readable, 'readable');
    };

    const handlePayableChange = () => {
        onpayableChange(!payable, 'payable');
    };

    const handlePayableSCChange = () => {
        onpayableSCChange(!payableSC, 'payableSC');
    };



    return (
        <form className="toggle-form">
            <label>
                <span>Upgradeable:       </span>
                <Switch checked={upgradeable} onChange={handleUpgradeableChange}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                />
            </label>
            <label>
                <span>Readable:</span>
                <Switch checked={readable} onChange={handleReadableChange}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                />
            </label>
            <label>
                <span> Payable: </span>
                <Switch checked={payable} onChange={handlePayableChange}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                />
            </label>
            <label>
                <span>Payable by SC:</span>
                <Switch checked={payableSC} onChange={handlePayableSCChange}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                    id="material-switch"
                />
            </label>
        </form>
    );
};

export default ToggleForm;