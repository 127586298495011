export const TYPE_ENV_MAIN = 'mainnet';
export const TYPE_ENV_TEST = 'testnet';
export const TYPE_ENV_DEV = 'devnet';
export const TYPE_ENV_DEV2 = 'devnet2';

/* CONTRATOS: Ponemos los contratos que vaya a usar el proyecto */
export const avb_SC: { [key: string]: string } = {};
avb_SC[TYPE_ENV_TEST] = '';
avb_SC[TYPE_ENV_DEV] = '';
avb_SC[TYPE_ENV_MAIN] = '';


export const chainID_config: { [key: string]: string } = {};
avb_SC[TYPE_ENV_MAIN] = '1';
avb_SC[TYPE_ENV_TEST] = 'T';
avb_SC[TYPE_ENV_DEV] = 'D';
avb_SC[TYPE_ENV_DEV2] = 'D2';

/*  URLs de cosulta al gateway de Elrond  */
export const avb_gatewayURL: { [key: string]: string } = {};
avb_gatewayURL[TYPE_ENV_MAIN] = 'https://gateway.multiversx.com';
avb_gatewayURL[TYPE_ENV_TEST] = 'https://testnet-gateway.multiversx.com';
avb_gatewayURL[TYPE_ENV_DEV] = 'https://' + TYPE_ENV_DEV + '-gateway.multiversx.com';
avb_gatewayURL[TYPE_ENV_DEV2] = 'https://' + TYPE_ENV_DEV2 + '-gateway.multiversx.com';

export const avb_apiURL: { [key: string]: string } = {};
avb_apiURL[TYPE_ENV_MAIN] = 'https://api.multiversx.com';
avb_apiURL[TYPE_ENV_TEST] = 'https://testnet-api.multiversx.com';
avb_apiURL[TYPE_ENV_DEV] = 'https://' + TYPE_ENV_DEV + '-api.multiversx.com';
avb_apiURL[TYPE_ENV_DEV2] = 'https://' + TYPE_ENV_DEV2 + '-api.multiversx.com';

export const avb_explorerURL: { [key: string]: string } = {};
avb_explorerURL[TYPE_ENV_MAIN] = 'https://explorer.multiversx.com';
avb_explorerURL[TYPE_ENV_TEST] = 'https://testnet-explorer.multiversx.com';
avb_explorerURL[TYPE_ENV_DEV] = 'https://' + TYPE_ENV_DEV + '-explorer.multiversx.com';
avb_explorerURL[TYPE_ENV_DEV2] = 'https://' + TYPE_ENV_DEV2 + '-explorer.multiversx.com';





/* TOKENS: Ponemos los tokens que vayamos a manejar en el proyecto. Ojo que los nombres no coinciden de un entorno a otro */
interface token_type {
  tokenIdentifier: string;
  decimals: number;
  logo: string;
}

export const avb_tokenList: { [key: string]: token_type[] } = {};
avb_tokenList[TYPE_ENV_TEST] = [
  {
    tokenIdentifier: 'EGLD',
    decimals: 18,
    logo: '/img/elrond-symbol.svg'
  },
  {
    tokenIdentifier: 'DICE-1382da',
    decimals: 6,
    logo: 'https://media.elrond.com/tokens/asset/DICE-9749cc/logo.svg'
  },
  {
    tokenIdentifier: 'MEX-27f4cd',
    decimals: 18,
    logo: 'https://testnet-media.elrond.com/tokens/asset/MEX-27f4cd/logo.svg'
  },
  {
    tokenIdentifier: 'ASH-76f082',
    decimals: 18,
    logo: 'https://testnet-media.elrond.com/tokens/asset/ASH-76f082/logo.svg'
  },
  {
    tokenIdentifier: 'USDC-a63f36',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/USDC-c76f1f/logo.svg'
  },
  {
    tokenIdentifier: 'RIDE-e8299a',
    decimals: 18,
    logo: 'https://testnet-media.elrond.com/tokens/asset/RIDE-e8299a/logo.svg'
  },
  {
    tokenIdentifier: 'DTK-c93fdf',
    decimals: 18,
    logo: 'https://testnet-media.elrond.com/tokens/asset/DTK-c93fdf/logo.svg'
  },
  {
    tokenIdentifier: 'LKMEX-f4d898',
    decimals: 18,
    logo: 'https://testnet-media.elrond.com/tokens/asset/LKMEX-f4d898/logo.svg'
  }
];

avb_tokenList[TYPE_ENV_DEV] = [
  {
    tokenIdentifier: 'EGLD',
    decimals: 18,
    logo: '/img/elrond-symbol.svg'
  },
  {
    tokenIdentifier: 'LOTTO-20e1fa',
    decimals: 18,
    logo: 'https://media.elrond.com/tokens/asset/DICE-9749cc/logo.svg'
  },
  {
    tokenIdentifier: 'USDC-8d4068',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/USDC-c76f1f/logo.svg'
  }
];

avb_tokenList[TYPE_ENV_MAIN] = [
  {
    tokenIdentifier: 'DICE-9749cc',
    decimals: 6,
    logo: 'https://media.elrond.com/tokens/asset/DICE-9749cc/logo.svg'
  },
  {
    tokenIdentifier: 'EGLD',
    decimals: 18,
    logo: '/img/elrond-symbol.svg'
  },
  {
    tokenIdentifier: 'BNI-bb0a30',
    decimals: 18,
    logo: '/img/beni-logo.svg'
  },
  {
    tokenIdentifier: 'MEX-455c57',
    decimals: 18,
    logo: 'https://media.elrond.com/tokens/asset/MEX-455c57/logo.svg'
  },
  {
    tokenIdentifier: 'USDC-c76f1f',
    decimals: 6,
    logo: 'https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/USDC-c76f1f/logo.svg'
  },
  {
    tokenIdentifier: 'RIDE-7d18e9',
    decimals: 18,
    logo: 'https://media.elrond.com/tokens/asset/RIDE-7d18e9/logo.svg'
  },
  {
    tokenIdentifier: 'WATER-9ed400',
    decimals: 18,
    logo: 'https://media.elrond.com/tokens/asset/WATER-9ed400/logo.png'
  },
  {
    tokenIdentifier: 'LKMEX-aab910',
    decimals: 18,
    logo: 'https://media.elrond.com/tokens/asset/LKMEX-aab910/logo.svg'
  }
];
