import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import './common.scss';
import wasmIcon from '../../assets/img/wasm.png';
import ToggleForm from 'components/Flags/toggleForm';
import { Code } from "@multiversx/sdk-core";


interface FileUploadProps {
    onBooleanChange: (name: string, value: boolean) => void;
    onCodeContentChange: (content: string) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({ onBooleanChange, onCodeContentChange }) => {
    // State for selected file information
    const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
    const allowedExtensions = ['wasm'];

    // State for showing form fields and code content
    const [showFields, setShowFields] = useState(false);
    const [codeContent, setCodeContent] = useState<string>('');

    // State for boolean flag values
    const [booleanValues, setBooleanValues] = useState({
        upg: true,
        read: true,
        pay: false,
        paySC: false,
    });

    // Handle boolean flag changes
    const handleBooleanChange = (name: keyof typeof booleanValues) => {
        setBooleanValues(prevValues => ({ ...prevValues, [name]: !prevValues[name] }));
        onBooleanChange(name, !booleanValues[name]);
    };


    // Handle file drop
    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        const selectedFile = acceptedFiles[0];
        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase() || '';

        if (allowedExtensions.includes(fileExtension)) {
            setSelectedFileName(selectedFile.name);
            setShowFields(true);

            try {
                const buffer = await selectedFile.arrayBuffer();
                const uint8Array = new Uint8Array(buffer);
                const bufferNode = Buffer.from(uint8Array);
                const code = Code.fromBuffer(bufferNode);
                setCodeContent(code.toString());
                onCodeContentChange(code.toString());

                // Update boolean flag values
                handleBooleanChange('upg');
                handleBooleanChange('read');
                handleBooleanChange('pay');
                handleBooleanChange('paySC');
            } catch (error) {
                console.error('Error reading the file:', error);
            }
        } else {
            alert('Only WASM files are allowed.');
        }
    }, [handleBooleanChange]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className="upload-container" style={{ color: '#fff' }}>
            {selectedFileName ? (
                <div className="selected-file">
                    <img src={wasmIcon} alt="WASM Icon" className="icon" />
                    <p >{selectedFileName}</p>
                    {showFields && (
                        <div className="input-fields">
                            <ToggleForm
                                upgradeable={booleanValues.upg}
                                readable={booleanValues.read}
                                payable={booleanValues.pay}
                                payableSC={booleanValues.paySC}
                                onupgradeableChange={() => handleBooleanChange('upg')}
                                onreadableChange={() => handleBooleanChange('read')}
                                onpayableChange={() => handleBooleanChange('pay')}
                                onpayableSCChange={() => handleBooleanChange('paySC')}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={`file-upload-container dropzone ${isDragActive ? 'active' : ''}`} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className='text_grey'>Drag and drop WASM files here, or click to select files</p>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
