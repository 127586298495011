import React, { useState } from 'react';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import FileUpload from 'components/DragDrop/FileUpload';
import ABIUpload, { InputValue } from 'components/DragDrop/ABIUpload';
import './dashboard.scss';
import { verifyValueByType } from 'lib/functions';
import { deploySC } from 'lib/operationsSC/sendtx';
import { Dropdown } from 'react-bootstrap';
import { BsGearFill } from 'react-icons/bs';
import { dappDevnetV1, dappDevnetV2, dappMainnet, dappTesnet, explorerUrl } from 'config';

export interface BooleanValues {
  upg: boolean;
  read: boolean;
  pay: boolean;
  paySC: boolean;
}

const Dashboard = () => {
  const account = useGetAccountInfo();
  const { address } = account;
  const [showDropdown, setShowDropdown] = useState(false); // State to control the dropdown

  const [booleanValues, setBooleanValues] = useState<BooleanValues>({
    upg: false,
    read: false,
    pay: false,
    paySC: false,
  });

  // State for input values received from ABIUpload
  const [inputValues, setInputValues] = useState<InputValue[]>([]);

  // New state to store codeContent
  const [codeContent, setCodeContent] = useState<string>('');

  // Function to handle changes in codeContent
  const handleCodeContentChange = (content: string) => {
    setCodeContent(content);
  };

  // Handle boolean value changes
  const handleBooleanChange = (name: string, value: boolean) => {
    setBooleanValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  // Handle input values received from ABIUpload
  const handleABIInputChange = (inputValues: InputValue[]) => {
    setInputValues(inputValues);
  };

  // Handle form submission
  const handleSubmit = () => {

    // Check if all fields in inputValues.values are filled
    const allFieldsFilled = inputValues.length === 0 || inputValues.every(value => value.value !== '');

    if (!allFieldsFilled) {
      alert('Not all fields are filled');
      return; // Do not perform any action if not all fields are filled
    }

    let allValid = true; // Flag to track if all verifications are valid

    // Check data type and perform sendTX for SC deployment
    inputValues.forEach(input => {
      // Perform type checks based on the value in input.value
      // For example, if input.type is 'number', you can convert input.value to a number and take actions accordingly
      // If input.type is 'string', you can keep input.value as it is
      // Perform your verifications and actions here
      const isValid = verifyValueByType(input.type, input.value);

      if (!isValid) {
        alert(`Invalid value for ${input.name}. \nType ${input.type}`);
        allValid = false; // Set the flag to false if any verification fails
        // You can handle the behavior for invalid values here, like stopping the process or displaying an error message
      }
    });

    if (allValid) {
      deploySC(booleanValues, inputValues, codeContent, address);
    }
  };


  // OPTIONS DEVNET, TESNET, MAINNET
  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown); 
  };

  const handleDropdownSelect = (eventKey: string | null) => {
    setShowDropdown(false); 

    let url;
    switch (eventKey) {
      case 'mainnet':
        url = dappMainnet
        break;
      case 'testnet':
        url = dappTesnet
        break;
      case 'devnet':
        url = dappDevnetV1
        break;
      case 'devnetv2':
        url = dappDevnetV2
        break;


      default: url = dappMainnet
        return;
    }

    window.location.href = url;
  };
  return (
    <div className='primary_container py-4 text-center'>
      <h2 className='mb-3 text-light font-weight-bold'>Wallet Address:  </h2>
      <span className='text_grey'>
        <a href={`${explorerUrl}/accounts/${address}`} target="_blank" rel="noopener noreferrer">
          {address.slice(0, 5)}...{address.slice(-10)}
        </a>
      </span>

      <div className='d-flex justify-content-center align-items-center'>
        <Dropdown
          show={showDropdown}
          onToggle={handleDropdownToggle}
          onSelect={handleDropdownSelect}
          className="custom-dropdown"
        >
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-settings"
            className="dropdown-settings-btn"
          >
            <BsGearFill size={24} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="mainnet">Mainnet</Dropdown.Item>
            <Dropdown.Item eventKey="testnet">Testnet</Dropdown.Item>
            <Dropdown.Item eventKey="devnet">Devnet</Dropdown.Item>
            <Dropdown.Item eventKey="devnetv2">Devnet V2</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='upload-area d-flex justify-content-center align-top'>
        <FileUpload
          onBooleanChange={handleBooleanChange}
          onCodeContentChange={handleCodeContentChange} // Pass the function to the FileUpload component
        />
        <div className='mx-2'></div> {/* Spacer */}
        <ABIUpload onInputChange={handleABIInputChange} />
      </div>

      <button className='btn btn-primary rounded-0 mt-3 action-btn font-weight-bold' onClick={handleSubmit}>
        Deploy
      </button>
    </div>
  );
};
export default Dashboard;
