import React, { useState } from 'react';
import { useGetAccountInfo, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, NavItem, Nav, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import { ReactComponent as ElrondLogo } from './../../../assets/img/Logo/SVG/Asset 6.svg';
import './navbar.scss';

const Navbar = () => {
  const { account } = useGetAccountInfo();
  const isLoggedIn = useGetIsLoggedIn();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  return (
    <BsNavbar className='px-4 py-3 bg-black'>
      <div className='container-fluid align-items-center'>
        <Link
          className='d-flex align-items-center navbar-brand mr-0'
          to={isLoggedIn ? routeNames.dashboard : routeNames.home}
        >
          <ElrondLogo className='elrond-logo' />
          <span className='dapp-name'>{dAppName}</span>
        </Link>
        <Nav className='ml-auto'>
          <NavItem>
            <button
              className={`btn ${isLoggedIn ? 'btn-link rounded-0' : 'btn-primary action-btn rounded-0'}`}
              onClick={handleLogout}
            >
              {isLoggedIn ? 'Logout' : 'Login'}
            </button>
          </NavItem>

        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
